import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
      () => {
        _ctx.authStore.loggedIn ? _ctx.so() : _ctx.login();
      }
    ),
    class: ""
  }, [
    _createElementVNode("div", null, _toDisplayString(_ctx.authStore.loggedIn ? `Sign Out` : `Login`), 1)
  ]))
}