
import { Editor } from "@tiptap/core";

export default {
  props: {
    editor: {
      type: Editor,
    },
  },
};
