
import { ref, defineComponent, Ref } from "vue";
import { db } from "@/main";
import { collection, doc, setDoc, Timestamp } from "firebase/firestore";
import TiptapComponent from "../components/TiptapComponent.vue";
import { useAuthStore } from "@/store";
import { Editor } from "@tiptap/vue-3";

export default defineComponent({
  name: "CreatePostPage",
  components: {
    TiptapComponent,
  },
  data() {
    let error = "";
    let title = "";
    let image = "";
    let description = "";
    let embed = "";
    let editor: Ref<Editor | null> = ref(null);

    return {
      authStore: useAuthStore(),
      error,
      title,
      image,
      description,
      embed,
      editor,
      parseDate: (date: string) => {
        let parse = date.split(" ");

        if (parse.length >= 4)
          return `${parse[0]} | ${parse[1]}/${parse[2]}/${parse[3]}`;

        return "";
      },
    };
  },

  methods: {
    async submit() {
      this.description = this.editor?.getHTML() || "";

      try {
        await setDoc(doc(collection(db, "blogs")), {
          title: this.title,
          image: this.image,
          description: this.description,
          embed: this.embed,
          date: Timestamp.fromDate(new Date()),
        });

        this.$router.push(`/blog`);
      } catch (e) {
        console.log(e);
      }
    },
  },
});
