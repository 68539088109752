
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "MediaPage",
  data() {
    return {
      videos: [
        "/media-page/videos/zededit.mp4",
        "/media-page/videos/squishyedit.mp4",
        "/media-page/videos/alrightalmight.mp4",
        "/media-page/videos/simonedit.mp4",
        "/media-page/videos/qiyanaedit.mp4",
        "/media-page/videos/oasis.mp4",
      ],
      titles: [
        "Zed Montage (2019)",
        "Find A Way (2018)",
        "Alright Almight (2018)",
        "Pusha (2018)",
        "Qiyana Edit (2019)",
        "Oasis (2019)",
      ],
      descriptions: [
        "The inspiration behind this edit came from a good friend of mine, Nicholas Drobes. We had this running joke that I wasn't able to play a League of Legends champion. So, I played one game, popped off, made a video edit, and sent him this video.",
        "As I was experiementing with my edits back in 2019, I thought it would be a good idea to deviate from the AMVs I was currently making. At this time, I watched the RLCS Season 6 World Championship where Cloud 9, my favorite team, had won the tournament from starting low in the losers bracket. The intention was to make a video where I highlight their road to victory, using this clip as a flashback to the words of a song. The lyrics during this clip are 'I see the look on everybody faces, They know I'm great but they don't wanna face it.'",
        "This video is dear to me because it's the last serious edit I have made before putting a hold on my editing skills. It's, in my opinion, one of the best edits I've made and arguably better than some of the other top editor AMV channels out there.",
        "This was originally intended to be a music video for the wonderful artist, Simbad. We spent weeks making this music video, but it fell short due to money issues. Nonetheless, shout out to Simbad for wanting to push the boundaries of the music scene.",
        "I was really excited to play Qiyana, a champion in League of Legends, when she first came out. So much so, that I spent hours in the training tool practicing combos and testing the limits of her mechancis. In this video I highlighted the UI that she had for her dash abilities. I compared the UI range for Qiyana's jump to Ekko's jump to emphasize the misleading range upon the ability to dash through walls. In the end, nothing was changed.",
        "During my years in community college, and before I went to Japan, I took several film courses. This short film was made in FIL 1000, where we worked in a small group of 5 people to create a short film. This film the best in the class, and has recieved one award.",
      ],
      currentVideo: 0,
      width: ref(window.innerWidth),
    };
  },
  methods: {
    makeBoard() {
      return;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });

    setInterval(() => {
      this.currentVideo = (this.currentVideo + 1) % this.videos.length;
    }, 7000);
  },
});
