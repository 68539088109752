
import { defineComponent, DefineComponent } from "vue";
import * as THREE from "three";
import { particles } from "../scripts/particles";

export default defineComponent({
  name: "AnimationsPage",
  data() {
    const renderer = new THREE.WebGLRenderer({ alpha: true });

    return {
      renderer,
    };
  },
  mounted() {
    let scrollSpeed = 0;
    let scrollForce = 0;
    let scrollTotal = 0;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );

    this.renderer.setSize(window.innerWidth, window.innerHeight);

    const group = particles(scene);

    camera.position.z = 5;

    let headerDiv = this.$refs.header as unknown as HTMLDivElement;
    let navbarDiv = this.$refs.navbar as unknown as HTMLDivElement;
    document.addEventListener("wheel", (e) => {
      scrollForce += e.deltaY;
      scrollTotal += e.deltaY;
      scrollTotal = Math.min(window.outerHeight, Math.max(0, scrollTotal));

      headerDiv.style.opacity = (
        1 -
        Math.min(scrollTotal, 600) / 600
      ).toString();

      if (scrollTotal > 600)
        navbarDiv.style.backgroundColor = `rgba(0, 0, 0, 1)`;
      else navbarDiv.style.backgroundColor = `rgba(0, 0, 0, 0)`;
    });

    window.addEventListener("resize", () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    });

    (this.$refs.parentDiv as unknown as HTMLDivElement).appendChild(
      this.renderer.domElement
    );

    const rend = this.renderer;

    const animate = function (time: number) {
      requestAnimationFrame(animate);

      for (let plane of group.children) {
        plane.rotation.x += Math.random() / 100;
        plane.rotation.y += Math.random() / 100;
        plane.rotation.z += Math.random() / 100;
      }

      if (scrollForce != 0) {
        scrollSpeed += (scrollForce - scrollSpeed) / 10;
        group.rotation.x += scrollSpeed / 10000;
        scrollForce *= 0.9;
      }

      group.rotation.y += 0.0001;
      group.rotation.x += 0.0001;

      rend.render(scene, camera);
    };

    animate(0);
  },
  beforeUnmount() {
    this.renderer.forceContextLoss();
  },
});
