
import { defineComponent } from "vue";
import NavHamburgerMenu from "../components/NavHamburgerMenu.vue";
import NavDesktopMenu from "../components/NavDesktopMenu.vue";

export default defineComponent({
  name: "NavigationBar",
  components: {
    NavHamburgerMenu,
    NavDesktopMenu,
  },
  data() {
    return {
      width: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
  },
});
