
import { ref, defineComponent, Ref } from "vue";
import { db } from "@/main";
import {
  collection,
  doc,
  getDoc,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import TiptapComponent from "../components/TiptapComponent.vue";
import { useAuthStore } from "@/store";
import { Editor } from "@tiptap/vue-3";

export default defineComponent({
  name: "UpdatePostPage",
  components: {
    TiptapComponent,
  },
  data() {
    let id = "";
    let error = "";
    let title = "";
    let image = "";
    let description = "";
    let embed = "";
    let editor: Ref<Editor | null> = ref(null);

    return {
      authStore: useAuthStore(),
      id,
      error,
      title,
      image,
      description,
      embed,
      editor,
      parseDate: (date: string) => {
        let parse = date.split(" ");

        if (parse.length >= 4)
          return `${parse[0]} | ${parse[1]}/${parse[2]}/${parse[3]}`;

        return "";
      },
    };
  },

  async mounted() {
    if (!this.authStore.admin) this.$router.push("/");

    try {
      this.id =
        typeof this.$route.params.id === "string"
          ? this.$route.params.id
          : this.$route.params.id[0];

      const post = await getDoc(doc(collection(db, "blogs"), this.id));
      const data = post.data();

      if (!data) {
        this.$router.push("/blog");
        return;
      }

      this.title = data.title;
      this.image = data.image;
      this.description = data.description;
      this.embed = data.embed;
      this.editor?.commands.setContent(data.description);
    } catch (e) {
      console.log(e);
    }
  },

  methods: {
    async submit() {
      this.description = this.editor?.getHTML() || "";

      try {
        await updateDoc(doc(collection(db, "blogs"), this.id), {
          title: this.title,
          image: this.image,
          description: this.description,
          embed: this.embed,
          upDate: Timestamp.fromDate(new Date()),
        });

        this.$router.push(`/blog/${this.id}`);
      } catch (e) {
        console.log(e);
      }
    },
  },
});
