
import { ref, defineComponent, Ref } from "vue";
import LoginButton from "../components/LoginButton.vue";
export default defineComponent({
  name: "NavHamburgerMenu",
  components: {
    LoginButton,
  },
  data() {
    return {
      open: ref(false),
      init: ref(true),
      backgroundDiv: ref(null) as unknown as Ref<HTMLDivElement>,
      height: ref(window.innerHeight),
    };
  },
  mounted() {
    this.backgroundDiv = this.$refs.backgroundDiv as unknown as HTMLDivElement;

    window.addEventListener("resize", () => {
      this.height = window.innerHeight;
    });
  },
  methods: {
    toggle() {
      this.open = !this.open;
      if (this.init) {
        this.init = false;
        this.backgroundDiv.style.background = `#000000`;
      }
    },
  },
});
