
import { defineComponent } from "vue";
import { db } from "@/main";
import {
  collection,
  DocumentData,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import { useAuthStore } from "@/store";

import LoadingComponent from "../components/LoadingComponent.vue";

export default defineComponent({
  name: "BlogPage",
  components: {
    LoadingComponent,
  },
  data() {
    let posts: Map<string, DocumentData> = new Map<string, DocumentData>();

    return {
      authStore: useAuthStore(),
      posts,
      parseDate: (date: string) => {
        let parse = date.split(" ");

        if (parse.length >= 4)
          return `${parse[0]} | ${parse[1]}/${parse[2]}/${parse[3]}`;

        return "";
      },
    };
  },
  async mounted() {
    const docs = await getDocs(
      query(collection(db, "blogs"), orderBy("date", "desc"))
    );

    docs.forEach((doc) => {
      this.posts.set(doc.id, doc.data());
    });
  },
});
